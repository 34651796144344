.p-fieldset {
  border: $panelContentBorder;
  border-radius: $borderRadius;

  background: $panelContentBg;
  color: $panelContentTextColor;

  .p-fieldset-legend {
    padding: $panelHeaderPadding;
    border: $panelHeaderBorder;
    border-radius: $borderRadius;

    background: $panelHeaderBg;
    color: $panelHeaderTextColor;

    font-weight: $panelHeaderFontWeight;
  }

  &.p-fieldset-toggleable {
    .p-fieldset-legend {
      padding: 0;

      transition: $actionIconTransition;

      a {
        padding: $panelHeaderPadding;
        border-radius: $borderRadius;

        color: $panelHeaderTextColor;

        transition: $listItemTransition;

        .p-fieldset-toggler {
          margin-right: $inlineSpacing;
        }

        &:focus {
          @include focused();
        }
      }

      &:hover {
        border-color: $panelHeaderHoverBorderColor;

        background: $panelHeaderHoverBg;
        color: $panelHeaderTextHoverColor;
      }
    }
  }

  .p-fieldset-content {
    padding: $panelContentPadding;
  }
}
