@mixin icon-override($icon) {
  &::before {
    content: $icon;
  }
}
@mixin focused() {
  box-shadow: $focusShadow;
  outline: $focusOutline;
  outline-offset: $focusOutlineOffset;
}
@mixin focused-input() {
  @include focused();
  border-color: $inputFocusBorderColor;
}
@mixin focused-listitem() {
  box-shadow: $inputListItemFocusShadow;
  outline: $focusOutline;
  outline-offset: $focusOutlineOffset;
}
@mixin invalid-input() {
  border-color: $inputErrorBorderColor;
}
@mixin menuitem-link {
  padding: $menuitemPadding;
  border-radius: $menuitemBorderRadius;

  color: $menuitemTextColor;

  user-select: none;

  transition: $listItemTransition;

  .p-menuitem-text {
    color: $menuitemTextColor;
  }

  .p-menuitem-icon {
    margin-right: $inlineSpacing;

    color: $menuitemIconColor;
  }

  .p-submenu-icon {
    color: $menuitemIconColor;
  }

  &:not(.p-disabled):hover {
    background: $menuitemHoverBg;

    .p-menuitem-text {
      color: $menuitemTextHoverColor;
    }

    .p-menuitem-icon {
      color: $menuitemIconHoverColor;
    }

    .p-submenu-icon {
      color: $menuitemIconHoverColor;
    }
  }

  &:focus {
    @include focused-listitem();
  }
}
@mixin horizontal-rootmenuitem-link {
  padding: $horizontalMenuRootMenuitemPadding;
  border-radius: $horizontalMenuRootMenuitemBorderRadius;

  color: $horizontalMenuRootMenuitemTextColor;

  user-select: none;

  transition: $listItemTransition;

  .p-menuitem-text {
    color: $horizontalMenuRootMenuitemTextColor;
  }

  .p-menuitem-icon {
    margin-right: $inlineSpacing;

    color: $horizontalMenuRootMenuitemIconColor;
  }

  .p-submenu-icon {
    margin-left: $inlineSpacing;

    color: $horizontalMenuRootMenuitemIconColor;
  }

  &:not(.p-disabled):hover {
    background: $horizontalMenuRootMenuitemHoverBg;

    .p-menuitem-text {
      color: $horizontalMenuRootMenuitemTextHoverColor;
    }

    .p-menuitem-icon {
      color: $horizontalMenuRootMenuitemIconHoverColor;
    }

    .p-submenu-icon {
      color: $horizontalMenuRootMenuitemIconHoverColor;
    }
  }

  &:focus {
    @include focused-listitem();
  }
}
@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }

  :-moz-placeholder {
    @content;
  }

  ::-moz-placeholder {
    @content;
  }

  :-ms-input-placeholder {
    @content;
  }
}
@mixin scaledPadding($val, $scale) {
  padding: nth($val, 1) * $scale nth($val, 2) * $scale;
}
@mixin scaledFontSize($val, $scale) {
  font-size: $val * $scale;
}
@mixin nested-submenu-indents($val, $index, $length) {
  ul {
    li {
      a {
        padding-left: $val * ($index + 1);
      }

      @if $index < $length {
        @include nested-submenu-indents($val, $index + 2, $length);
      }
    }
  }
}
@mixin action-icon() {
  width: $actionIconWidth;
  height: $actionIconHeight;
  border: $actionIconBorder;
  border-radius: $actionIconBorderRadius;

  background: $actionIconBg;
  color: $actionIconColor;

  transition: $actionIconTransition;

  &:enabled:hover {
    border-color: $actionIconHoverBorderColor;

    background: $actionIconHoverBg;
    color: $actionIconHoverColor;
  }

  &:focus {
    @include focused();
  }
}
