/* roboto-regular - latin-ext_latin */
@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Regular'),
    url('./fonts/roboto-v27-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./fonts/roboto-v27-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500 - latin-ext_latin */
@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: 'Roboto';
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('./fonts/roboto-v27-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./fonts/roboto-v27-latin-ext_latin_cyrillic-ext_cyrillic-500.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700 - latin-ext_latin */
@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('./fonts/roboto-v27-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./fonts/roboto-v27-latin-ext_latin_cyrillic-ext_cyrillic-700.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
