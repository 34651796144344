$navigationPanelBgColor: #e8eaf6;
$navigationPanelWidth: 240px;
$navigationPanelPadding: 1rem;
$navigationPanelScrollBarWidth: 4px;
$navigationPanelScrollThumbBg: rgba(0, 0, 0, 0.26);
$navigationPanelScrollThumbBg: rgba(0, 0, 0, 0.26);
$navigationPanelBorder: 1px solid rgba(0, 0, 0, 0.12);

$accordionContentBg: $navigationPanelBgColor;
$accordionHeaderHoverBg: #c5cae9;
$accordionHeaderBg: $accordionContentBg;
$accordionHeaderActiveHoverBg: $accordionContentBg;
$accordionHeaderActiveBg: $accordionContentBg;

$uploadImageBgColor: $navigationPanelBgColor;
$uploadImageBorderColor: #7b61ff;
$uploadImageCaptionColor: rgba(0, 0, 0, 0.6);
