.p-virtualscroller {
  .p-virtualscroller-header {
    padding: $tableHeaderPadding;
    border: $tableHeaderBorder;
    border-width: $tableHeaderBorderWidth;

    background: $tableHeaderBg;
    color: $tableHeaderTextColor;

    font-weight: $tableHeaderFontWeight;
  }

  .p-virtualscroller-content {
    padding: $dataViewContentPadding;
    border: $dataViewContentBorder;

    background: $tableBodyRowBg;
    color: $tableBodyRowTextColor;
  }

  .p-virtualscroller-footer {
    padding: $tableFooterPadding;
    border: $tableFooterBorder;
    border-width: $tableFooterBorderWidth;
    border-bottom-right-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;

    background: $tableFooterBg;
    color: $tableFooterTextColor;

    font-weight: $tableFooterFontWeight;
  }
}
