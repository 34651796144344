.p-button {
  padding: $buttonPadding;
  border: $buttonBorder;
  border-radius: $borderRadius;

  background: $buttonBg;
  color: $buttonTextColor;

  font-size: $fontSize;

  transition: $formElementTransition;

  &:enabled:hover {
    border-color: $buttonHoverBorderColor;

    background: $buttonHoverBg;
    color: $buttonTextHoverColor;
  }

  &:enabled:active {
    border-color: $buttonActiveBorderColor;

    background: $buttonActiveBg;
    color: $buttonTextActiveColor;
  }

  &.p-button-outlined {
    border: $outlinedButtonBorder;

    background-color: transparent;
    color: $buttonBg;

    &:enabled:hover {
      border: $outlinedButtonBorder;

      background: rgba($buttonBg, $textButtonHoverBgOpacity);
      color: $buttonBg;
    }

    &:enabled:active {
      border: $outlinedButtonBorder;

      background: rgba($buttonBg, $textButtonActiveBgOpacity);
      color: $buttonBg;
    }

    &.p-button-plain {
      border-color: $plainButtonTextColor;

      color: $plainButtonTextColor;

      &:enabled:hover {
        background: $plainButtonHoverBgColor;
        color: $plainButtonTextColor;
      }

      &:enabled:active {
        background: $plainButtonActiveBgColor;
        color: $plainButtonTextColor;
      }
    }
  }

  &.p-button-text {
    border-color: transparent;

    background-color: transparent;
    color: $buttonBg;

    &:enabled:hover {
      border-color: transparent;

      background: rgba($buttonBg, $textButtonHoverBgOpacity);
      color: $buttonBg;
    }

    &:enabled:active {
      border-color: transparent;

      background: rgba($buttonBg, $textButtonActiveBgOpacity);
      color: $buttonBg;
    }

    &.p-button-plain {
      color: $plainButtonTextColor;

      &:enabled:hover {
        background: $plainButtonHoverBgColor;
        color: $plainButtonTextColor;
      }

      &:enabled:active {
        background: $plainButtonActiveBgColor;
        color: $plainButtonTextColor;
      }
    }
  }

  &:focus {
    @include focused();
  }

  .p-button-icon-left {
    margin-right: $inlineSpacing;
  }

  .p-button-icon-right {
    margin-left: $inlineSpacing;
  }

  .p-button-icon-bottom {
    margin-top: $inlineSpacing;
  }

  .p-button-icon-top {
    margin-bottom: $inlineSpacing;
  }

  .p-badge {
    min-width: $fontSize;
    height: $fontSize;
    margin-left: $inlineSpacing;

    background-color: $buttonTextColor;
    color: $buttonBg;

    line-height: $fontSize;
  }

  &.p-button-raised {
    box-shadow: $raisedButtonShadow;
  }

  &.p-button-rounded {
    border-radius: $roundedButtonBorderRadius;
  }

  &.p-button-icon-only {
    width: $buttonIconOnlyWidth;
    padding: $buttonIconOnlyPadding;

    .p-button-icon-left,
    .p-button-icon-right {
      margin: 0;
    }

    &.p-button-rounded {
      height: $buttonIconOnlyWidth;
      border-radius: 50%;
    }
  }

  &.p-button-sm {
    @include scaledFontSize($fontSize, $scaleSM);
    @include scaledPadding($buttonPadding, $scaleSM);

    .p-button-icon {
      @include scaledFontSize($primeIconFontSize, $scaleSM);
    }
  }

  &.p-button-lg {
    @include scaledFontSize($fontSize, $scaleLG);
    @include scaledPadding($buttonPadding, $scaleLG);

    .p-button-icon {
      @include scaledFontSize($primeIconFontSize, $scaleLG);
    }
  }
}

.p-fluid {
  .p-button {
    width: 100%;
  }

  .p-button-icon-only {
    width: $buttonIconOnlyWidth;
  }

  .p-buttonset {
    display: flex;

    .p-button {
      flex: 1;
    }
  }
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
  border: $secondaryButtonBorder;

  background: $secondaryButtonBg;
  color: $secondaryButtonTextColor;

  &:enabled:hover {
    border-color: $secondaryButtonHoverBorderColor;

    background: $secondaryButtonHoverBg;
    color: $secondaryButtonTextHoverColor;
  }

  &:enabled:focus {
    box-shadow: $secondaryButtonFocusShadow;
  }

  &:enabled:active {
    border-color: $secondaryButtonActiveBorderColor;

    background: $secondaryButtonActiveBg;
    color: $secondaryButtonTextActiveColor;
  }

  &.p-button-outlined {
    border: $outlinedButtonBorder;

    background-color: transparent;
    color: $secondaryButtonBg;

    &:enabled:hover {
      border: $outlinedButtonBorder;

      background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
      color: $secondaryButtonBg;
    }

    &:enabled:active {
      border: $outlinedButtonBorder;

      background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
      color: $secondaryButtonBg;
    }
  }

  &.p-button-text {
    border-color: transparent;

    background-color: transparent;
    color: $secondaryButtonBg;

    &:enabled:hover {
      border-color: transparent;

      background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
      color: $secondaryButtonBg;
    }

    &:enabled:active {
      border-color: transparent;

      background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
      color: $secondaryButtonBg;
    }
  }
}

.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button {
  border: $infoButtonBorder;

  background: $infoButtonBg;
  color: $infoButtonTextColor;

  &:enabled:hover {
    border-color: $infoButtonHoverBorderColor;

    background: $infoButtonHoverBg;
    color: $infoButtonTextHoverColor;
  }

  &:enabled:focus {
    box-shadow: $infoButtonFocusShadow;
  }

  &:enabled:active {
    border-color: $infoButtonActiveBorderColor;

    background: $infoButtonActiveBg;
    color: $infoButtonTextActiveColor;
  }

  &.p-button-outlined {
    border: $outlinedButtonBorder;

    background-color: transparent;
    color: $infoButtonBg;

    &:enabled:hover {
      border: $outlinedButtonBorder;

      background: rgba($infoButtonBg, $textButtonHoverBgOpacity);
      color: $infoButtonBg;
    }

    &:enabled:active {
      border: $outlinedButtonBorder;

      background: rgba($infoButtonBg, $textButtonActiveBgOpacity);
      color: $infoButtonBg;
    }
  }

  &.p-button-text {
    border-color: transparent;

    background-color: transparent;
    color: $infoButtonBg;

    &:enabled:hover {
      border-color: transparent;

      background: rgba($infoButtonBg, $textButtonHoverBgOpacity);
      color: $infoButtonBg;
    }

    &:enabled:active {
      border-color: transparent;

      background: rgba($infoButtonBg, $textButtonActiveBgOpacity);
      color: $infoButtonBg;
    }
  }
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
  border: $successButtonBorder;

  background: $successButtonBg;
  color: $successButtonTextColor;

  &:enabled:hover {
    border-color: $successButtonHoverBorderColor;

    background: $successButtonHoverBg;
    color: $successButtonTextHoverColor;
  }

  &:enabled:focus {
    box-shadow: $successButtonFocusShadow;
  }

  &:enabled:active {
    border-color: $successButtonActiveBorderColor;

    background: $successButtonActiveBg;
    color: $successButtonTextActiveColor;
  }

  &.p-button-outlined {
    border: $outlinedButtonBorder;

    background-color: transparent;
    color: $successButtonBg;

    &:enabled:hover {
      border: $outlinedButtonBorder;

      background: rgba($successButtonBg, $textButtonHoverBgOpacity);
      color: $successButtonBg;
    }

    &:enabled:active {
      border: $outlinedButtonBorder;

      background: rgba($successButtonBg, $textButtonActiveBgOpacity);
      color: $successButtonBg;
    }
  }

  &.p-button-text {
    border-color: transparent;

    background-color: transparent;
    color: $successButtonBg;

    &:enabled:hover {
      border-color: transparent;

      background: rgba($successButtonBg, $textButtonHoverBgOpacity);
      color: $successButtonBg;
    }

    &:enabled:active {
      border-color: transparent;

      background: rgba($successButtonBg, $textButtonActiveBgOpacity);
      color: $successButtonBg;
    }
  }
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
  border: $warningButtonBorder;

  background: $warningButtonBg;
  color: $warningButtonTextColor;

  &:enabled:hover {
    border-color: $warningButtonHoverBorderColor;

    background: $warningButtonHoverBg;
    color: $warningButtonTextHoverColor;
  }

  &:enabled:focus {
    box-shadow: $warningButtonFocusShadow;
  }

  &:enabled:active {
    border-color: $warningButtonActiveBorderColor;

    background: $warningButtonActiveBg;
    color: $warningButtonTextActiveColor;
  }

  &.p-button-outlined {
    border: $outlinedButtonBorder;

    background-color: transparent;
    color: $warningButtonBg;

    &:enabled:hover {
      border: $outlinedButtonBorder;

      background: rgba($warningButtonBg, $textButtonHoverBgOpacity);
      color: $warningButtonBg;
    }

    &:enabled:active {
      border: $outlinedButtonBorder;

      background: rgba($warningButtonBg, $textButtonActiveBgOpacity);
      color: $warningButtonBg;
    }
  }

  &.p-button-text {
    border-color: transparent;

    background-color: transparent;
    color: $warningButtonBg;

    &:enabled:hover {
      border-color: transparent;

      background: rgba($warningButtonBg, $textButtonHoverBgOpacity);
      color: $warningButtonBg;
    }

    &:enabled:active {
      border-color: transparent;

      background: rgba($warningButtonBg, $textButtonActiveBgOpacity);
      color: $warningButtonBg;
    }
  }
}

.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button {
  border: $helpButtonBorder;

  background: $helpButtonBg;
  color: $helpButtonTextColor;

  &:enabled:hover {
    border-color: $helpButtonHoverBorderColor;

    background: $helpButtonHoverBg;
    color: $helpButtonTextHoverColor;
  }

  &:enabled:focus {
    box-shadow: $helpButtonFocusShadow;
  }

  &:enabled:active {
    border-color: $helpButtonActiveBorderColor;

    background: $helpButtonActiveBg;
    color: $helpButtonTextActiveColor;
  }

  &.p-button-outlined {
    border: $outlinedButtonBorder;

    background-color: transparent;
    color: $helpButtonBg;

    &:enabled:hover {
      border: $outlinedButtonBorder;

      background: rgba($helpButtonBg, $textButtonHoverBgOpacity);
      color: $helpButtonBg;
    }

    &:enabled:active {
      border: $outlinedButtonBorder;

      background: rgba($helpButtonBg, $textButtonActiveBgOpacity);
      color: $helpButtonBg;
    }
  }

  &.p-button-text {
    border-color: transparent;

    background-color: transparent;
    color: $helpButtonBg;

    &:enabled:hover {
      border-color: transparent;

      background: rgba($helpButtonBg, $textButtonHoverBgOpacity);
      color: $helpButtonBg;
    }

    &:enabled:active {
      border-color: transparent;

      background: rgba($helpButtonBg, $textButtonActiveBgOpacity);
      color: $helpButtonBg;
    }
  }
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
  border: $dangerButtonBorder;

  background: $dangerButtonBg;
  color: $dangerButtonTextColor;

  &:enabled:hover {
    border-color: $dangerButtonHoverBorderColor;

    background: $dangerButtonHoverBg;
    color: $dangerButtonTextHoverColor;
  }

  &:enabled:focus {
    box-shadow: $dangerButtonFocusShadow;
  }

  &:enabled:active {
    border-color: $dangerButtonActiveBorderColor;

    background: $dangerButtonActiveBg;
    color: $dangerButtonTextActiveColor;
  }

  &.p-button-outlined {
    border: $outlinedButtonBorder;

    background-color: transparent;
    color: $dangerButtonBg;

    &:enabled:hover {
      border: $outlinedButtonBorder;

      background: rgba($dangerButtonBg, $textButtonHoverBgOpacity);
      color: $dangerButtonBg;
    }

    &:enabled:active {
      border: $outlinedButtonBorder;

      background: rgba($dangerButtonBg, $textButtonActiveBgOpacity);
      color: $dangerButtonBg;
    }
  }

  &.p-button-text {
    border-color: transparent;

    background-color: transparent;
    color: $dangerButtonBg;

    &:enabled:hover {
      border-color: transparent;

      background: rgba($dangerButtonBg, $textButtonHoverBgOpacity);
      color: $dangerButtonBg;
    }

    &:enabled:active {
      border-color: transparent;

      background: rgba($dangerButtonBg, $textButtonActiveBgOpacity);
      color: $dangerButtonBg;
    }
  }
}

.p-button.p-button-link {
  border: transparent;

  background: transparent;
  color: $linkButtonColor;

  &:enabled:hover {
    border-color: transparent;

    background: transparent;
    color: $linkButtonHoverColor;

    .p-button-label {
      text-decoration: $linkButtonTextHoverDecoration;
    }
  }

  &:enabled:focus {
    border-color: transparent;

    background: transparent;
    box-shadow: $linkButtonFocusShadow;
  }

  &:enabled:active {
    border-color: transparent;

    background: transparent;
    color: $linkButtonColor;
  }
}
