.p-listbox {
  border: $inputListBorder;
  border-radius: $borderRadius;

  background: $inputListBg;
  color: $inputListTextColor;

  .p-listbox-header {
    margin: $inputListHeaderMargin;
    padding: $inputListHeaderPadding;
    border-bottom: $inputListHeaderBorder;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;

    background: $inputListHeaderBg;
    color: $inputListHeaderTextColor;

    .p-listbox-filter {
      padding-right: nth($inputPadding, 2) + $primeIconFontSize;
    }

    .p-listbox-filter-icon {
      right: nth($inputPadding, 2);

      color: $inputIconColor;
    }

    .p-checkbox {
      margin-right: $inlineSpacing;
    }
  }

  .p-listbox-list {
    padding: $inputListPadding;

    .p-listbox-item {
      margin: $inputListItemMargin;
      padding: $inputListItemPadding;
      border: $inputListItemBorder;
      border-radius: $inputListItemBorderRadius;

      color: $inputListItemTextColor;

      transition: $listItemTransition;

      &.p-highlight {
        background: $highlightBg;
        color: $highlightTextColor;
      }

      &:focus {
        @include focused-listitem();
      }

      .p-checkbox {
        margin-right: $inlineSpacing;
      }
    }
  }

  &:not(.p-disabled) {
    .p-listbox-item {
      &:not(.p-highlight):not(.p-disabled):hover {
        background: $inputListItemHoverBg;
        color: $inputListItemTextHoverColor;
      }
    }
  }

  &.p-error,
  &.p-invalid {
    @include invalid-input();
  }
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
  @include invalid-input();
}
