.p-panel {
  & .p-panel-header {
    padding: 1.5rem;

    & .p-panel-title {
      @extend .au-typo-h4;
    }

    & .p-panel-header-icon {
      color: var(--text-color);
    }
  }

  & .p-panel-content {
    padding: 0 1.5rem 1.5rem;
  }
}
