.p-skeleton {
  border-radius: $borderRadius;

  background-color: $skeletonBg;

  &::after {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      $skeletonAnimationBg,
      rgba(255, 255, 255, 0)
    );
  }
}
