$arrWidth: 8px;
$arrRound: 3px;
$arrOffset: 20px;
$contentMargin: $arrWidth * 1.4142 + 2;
$contentOffset: $arrOffset + $arrWidth;

.carvector-popover {
  position: relative;
  display: inline-block;

  width: 100%;

  &-open {
    z-index: 3;
  }

  & .carvector-popover-content {
    position: absolute;

    min-width: 140px;
    padding: 5px 0;
    border-radius: 5px;

    background: #e8eaf5;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

    white-space: nowrap;

    &-trigger {
      display: flex;
      justify-content: space-between;
    }

    &-list {
      padding: 7px 14px;

      & a {
        display: inline-block;

        width: 100%;

        color: #000;

        cursor: pointer;

        &:hover {
          color: #4350af;
        }
      }

      & svg {
        margin-left: 20px;
      }

      &:hover {
        background: #dcfddf;
      }
    }

    &::after {
      z-index: -1;

      box-shadow: 0 0 16px rgba(black, 0.1), 0 4px 12px rgba(black, 0.1);
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: -1000;

      border: $arrWidth #e8eaf5 solid;

      transform: rotate(45deg);
    }

    &-bottom-left,
    &-bottom-right {
      top: 100%;

      width: auto;
      margin-top: $contentMargin;

      &::before,
      &::after {
        top: -$arrWidth;
        left: $arrOffset;

        border-top-left-radius: $arrRound;
      }
    }

    &.right-popover {
      top: 0 !important;

      margin-top: -10px !important;
      margin-left: 30px !important;

      &.carvector-popover-content-bottom {
        &-left,
        &-right {
          &::before,
          &::after {
            top: $arrWidth + 2px;
            left: -($arrWidth - $arrRound);

            border-top-left-radius: $arrRound;
          }
        }
      }
    }
  }
}
