.p-multiselect {
  .p-multiselect-label,
  .p-multiselect-trigger {
    background: transparent;
    background-image: none;
  }

  .p-multiselect-label {
    border: 0 none;
  }

  &:not(.p-disabled).p-focus {
    box-shadow: inset 0 0 0 1px $inputFocusBorderColor,
      inset 0 0 0 1px $inputFocusBorderColor, inset 0 0 0 1px $inputFocusBorderColor,
      inset 0 0 0 1px $inputFocusBorderColor;
  }
}

.p-multiselect-item {
  .p-ink {
    background-color: rgba($primaryColor, 0.16);
  }
}

.p-input-filled {
  .p-multiselect {
    @include filled-input-wrapper();

    .p-multiselect-label {
      &:hover {
        background: transparent;
        background-image: none;
      }
    }
  }
}

.p-float-label {
  .p-multiselect-label {
    .p-multiselect-token {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
      padding: 0.25rem 1rem;
    }
  }
}

// stylelint-disable-next-line
.p-input-filled {
  .p-float-label {
    .p-multiselect {
      .p-multiselect-label {
        @include filled-input-float-label();
      }
    }

    .p-inputwrapper-filled {
      .p-multiselect {
        .p-multiselect-label {
          @include filled-input-float-label();
        }

        &.p-multiselect-chip {
          .p-multiselect-token {
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;

            .p-multiselect-token-icon {
              font-size: 75%;
            }
          }
        }
      }
    }
  }

  p-multiselect.ng-dirty.ng-invalid {
    .p-multiselect {
      @include invalid-filled-input-wrapper();
    }
  }
}

p-multiselect.ng-dirty.ng-invalid {
  .p-multiselect {
    &:not(.p-disabled).p-focus {
      box-shadow: inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor, inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor;
    }
  }
}
