.p-card {
  border-radius: $borderRadius;

  background: $panelContentBg;
  color: $panelContentTextColor;
  box-shadow: $cardShadow;

  .p-card-body {
    padding: $cardBodyPadding;
  }

  .p-card-title {
    margin-bottom: $inlineSpacing;

    font-weight: $cardTitleFontWeight;
    font-size: $cardTitleFontSize;
  }

  .p-card-subtitle {
    margin-bottom: $inlineSpacing;

    color: $cardSubTitleColor;

    font-weight: $cardSubTitleFontWeight;
  }

  .p-card-content {
    padding: $cardContentPadding;
  }

  .p-card-footer {
    padding: $cardFooterPadding;
  }
}
