.p-autocomplete {
  .p-autocomplete-loader {
    right: nth($inputPadding, 2);
  }

  &.p-autocomplete-dd {
    .p-autocomplete-loader {
      right: nth($inputPadding, 2) + $buttonIconOnlyWidth;
    }
  }

  .p-autocomplete-multiple-container {
    padding: nth($inputPadding, 1) / 2 nth($inputPadding, 2);

    &:not(.p-disabled):hover {
      border-color: $inputHoverBorderColor;
    }

    &:not(.p-disabled).p-focus {
      @include focused-input();
    }

    .p-autocomplete-input-token {
      padding: nth($inputPadding, 1) / 2 0;

      input {
        margin: 0;
        padding: 0;

        color: $textColor;

        font-size: $fontSize;
        font-family: $fontFamily;
      }
    }

    .p-autocomplete-token {
      margin-right: $inlineSpacing;
      padding: nth($inputPadding, 1) / 2 nth($inputPadding, 2);
      border-radius: $borderRadius;

      background: $highlightBg;
      color: $highlightTextColor;

      .p-autocomplete-token-icon {
        margin-left: $inlineSpacing;
      }
    }
  }

  &.p-error > .p-inputtext,
  &.p-invalid > .p-inputtext {
    @include invalid-input();
  }
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  @include invalid-input();
}

.p-autocomplete-panel {
  border: $inputOverlayBorder;
  border-radius: $borderRadius;

  background: $inputOverlayBg;
  color: $inputListTextColor;
  box-shadow: $inputOverlayShadow;

  .p-autocomplete-items {
    padding: $inputListPadding;

    .p-autocomplete-item {
      margin: $inputListItemMargin;
      padding: $inputListItemPadding;
      border: $inputListItemBorder;
      border-radius: $inputListItemBorderRadius;

      background: $inputListItemBg;
      color: $inputListItemTextColor;

      transition: $listItemTransition;

      &:hover {
        background: $inputListItemHoverBg;
        color: $inputListItemTextHoverColor;
      }

      &.p-highlight {
        background: $highlightBg;
        color: $highlightTextColor;
      }
    }
  }
}
