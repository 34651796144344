@import '../../styles/theme/custom-variables';

.au-typo-h1 {
  font-size: 45px;
  line-height: 48px;
}

.au-typo-h2 {
  font-size: 34px;
  line-height: 40px;
}

.au-typo-h3 {
  font-size: 24px;
  line-height: 32px;
}

.au-typo-h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
}

.au-typo-h5 {
  font-size: 16px;
  line-height: 24px;
}

.au-typo-h6 {
  font-size: 14px;
  line-height: 24px;
}

.au-typo-body {
  font-size: 14px;
  line-height: 20px;
}

.au-typo-body-medium {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.au-typo-body-caption {
  font-size: 12px;
  line-height: 16px;
}

.au-typo-body-second-caption {
  @extend .au-typo-body-caption;

  color: $uploadImageCaptionColor;
}

.au-typo-body-caption-bold {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}
