* {
  box-sizing: border-box;
}

.p-component {
  font-weight: $fontWeight;
  font-size: $fontSize;
  font-family: $fontFamily;
}

.p-component-overlay {
  background-color: $maskBg;

  transition-duration: $transitionDuration;
}

.p-disabled,
.p-component:disabled {
  opacity: $disabledOpacity;
}

.p-error,
.p-invalid {
  color: $errorColor;
}

.p-text-secondary {
  color: $textSecondaryColor;
}

.pi {
  font-size: $primeIconFontSize;
}

.p-link {
  border-radius: $borderRadius;

  font-size: $fontSize;
  font-family: $fontFamily;

  &:focus {
    @include focused();
  }
}

.p-ellipsis {
  overflow: $hidden;

  text-overflow: $ellipsis;
  white-space: $nowrap;
}
