.p-sidebar {
  padding: $panelContentPadding;
  border: $overlayContentBorder;

  background: $overlayContentBg;
  color: $panelContentTextColor;
  box-shadow: $overlayContainerShadow;

  .p-sidebar-close {
    @include action-icon();
  }
}

.p-sidebar-mask.p-component-overlay {
  background: $maskBg;
}
