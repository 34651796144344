.p-timeline {
  .p-timeline-event-marker {
    width: $timelineEventMarkerWidth;
    height: $timelineEventMarkerHeight;
    border: $timelineEventMarkerBorder;
    border-radius: $timelineEventMarkerBorderRadius;

    background-color: $timelineEventMarkerBackground;
  }

  .p-timeline-event-connector {
    background-color: $timelineEventColor;
  }

  &.p-timeline-vertical {
    .p-timeline-event-opposite,
    .p-timeline-event-content {
      padding: $timelineVerticalEventContentPadding;
    }

    .p-timeline-event-connector {
      width: $timelineEventConnectorSize;
    }
  }

  &.p-timeline-horizontal {
    .p-timeline-event-opposite,
    .p-timeline-event-content {
      padding: $timelineHorizontalEventContentPadding;
    }

    .p-timeline-event-connector {
      height: $timelineEventConnectorSize;
    }
  }
}
