.p-cascadeselect {
  border: $inputBorder;
  border-radius: $borderRadius;

  background: $inputBg;

  transition: $formElementTransition;

  &:not(.p-disabled):hover {
    border-color: $inputHoverBorderColor;
  }

  &:not(.p-disabled).p-focus {
    @include focused-input();
  }

  .p-cascadeselect-label {
    padding: $inputPadding;
    border: 0 none;

    background: transparent;

    &.p-placeholder {
      color: $inputPlaceholderTextColor;
    }

    &:enabled:focus {
      box-shadow: none;
      outline: 0 none;
    }
  }

  .p-cascadeselect-trigger {
    width: $inputGroupAddOnMinWidth;
    border-top-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;

    background: transparent;
    color: $inputIconColor;
  }

  &.p-error,
  &.p-invalid {
    @include invalid-input();
  }
}

.p-cascadeselect-panel {
  border: $inputOverlayBorder;
  border-radius: $borderRadius;

  background: $inputOverlayBg;
  color: $inputListTextColor;
  box-shadow: $inputOverlayShadow;

  .p-cascadeselect-items {
    padding: $inputListPadding;

    .p-cascadeselect-item {
      margin: $inputListItemMargin;
      border: $inputListItemBorder;
      border-radius: $inputListItemBorderRadius;

      background: $inputListItemBg;
      color: $inputListItemTextColor;

      transition: $listItemTransition;

      .p-cascadeselect-item-content {
        padding: $inputListItemPadding;

        &:focus {
          @include focused-listitem();
        }
      }

      &.p-highlight {
        background: $highlightBg;
        color: $highlightTextColor;
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        background: $inputListItemHoverBg;
        color: $inputListItemTextHoverColor;
      }

      .p-cascadeselect-group-icon {
        font-size: $menuitemSubmenuIconFontSize;
      }
    }
  }
}

.p-input-filled {
  .p-cascadeselect {
    background: $inputFilledBg;

    &:not(.p-disabled):hover {
      background-color: $inputFilledHoverBg;
    }

    &:not(.p-disabled).p-focus {
      background-color: $inputFilledFocusBg;
    }
  }
}
