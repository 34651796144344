.p-chip {
  padding: 0 nth($inputPadding, 2);
  border-radius: $chipBorderRadius;

  background-color: $chipBg;
  color: $chipTextColor;

  .p-chip-text {
    margin-top: nth($inputPadding, 1) / 2;
    margin-bottom: nth($inputPadding, 1) / 2;

    line-height: 1.5;
  }

  .p-chip-icon {
    margin-right: $inlineSpacing;
  }

  .pi-chip-remove-icon {
    margin-left: $inlineSpacing;
  }

  img {
    width: 1.5 + nth($inputPadding, 1);
    height: 1.5 + nth($inputPadding, 1);
    margin-right: $inlineSpacing;
    margin-left: -1 * nth($inputPadding, 2);
  }

  // stylelint-disable-next-line
  .pi-chip-remove-icon {
    border-radius: $borderRadius;

    transition: $actionIconTransition;

    &:focus {
      @include focused();
    }
  }
}
