@mixin filled-input() {
  @include filled-input-default();
  @include filled-input-interaction();
}
@mixin filled-input-wrapper() {
  @include filled-input-wrapper-default();
  @include filled-input-wrapper-interaction();
}
@mixin filled-input-default() {
  border: 1px solid transparent;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  background: $inputFilledBg no-repeat;
  background-image: linear-gradient(
      to bottom,
      $inputFocusBorderColor,
      $inputFocusBorderColor
    ),
    linear-gradient(to bottom, nth($inputBorder, 3), nth($inputBorder, 3));
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
  background-size: 0 2px, 100% 1px;
}
@mixin invalid-filled-input() {
  border-color: transparent;

  background-image: linear-gradient(
      to bottom,
      $inputErrorBorderColor,
      $inputErrorBorderColor
    ),
    linear-gradient(to bottom, $inputErrorBorderColor, $inputErrorBorderColor);

  &:enabled:focus {
    border-color: transparent;

    box-shadow: none;
  }
}
@mixin filled-input-interaction() {
  &:enabled:hover {
    border-color: transparent;

    background-color: $inputFilledHoverBg;
    background-image: linear-gradient(
        to bottom,
        $inputFocusBorderColor,
        $inputFocusBorderColor
      ),
      linear-gradient(to bottom, $inputHoverBorderColor, $inputHoverBorderColor);
  }

  &:enabled:focus {
    border-color: transparent;

    background-color: $inputFilledFocusBg;
    background-size: 100% 2px, 100% 1px;
    box-shadow: none;
  }

  &.ng-invalid.ng-dirty {
    @include invalid-filled-input();
  }
}
@mixin filled-input-wrapper-default() {
  @include filled-input-default();

  .p-inputtext {
    background-color: transparent;
    background-image: none;
  }
}
@mixin invalid-filled-input-wrapper() {
  border-color: transparent;

  background-image: linear-gradient(
      to bottom,
      $inputErrorBorderColor,
      $inputErrorBorderColor
    ),
    linear-gradient(to bottom, $inputErrorBorderColor, $inputErrorBorderColor);

  &:not(.p-disabled).p-focus {
    border-color: transparent;

    box-shadow: none;
  }
}
@mixin filled-input-wrapper-interaction() {
  &:not(.p-disabled):hover {
    border-color: transparent;

    background-color: $inputFilledHoverBg;
    background-image: linear-gradient(
        to bottom,
        $inputFocusBorderColor,
        $inputFocusBorderColor
      ),
      linear-gradient(to bottom, $emphasis-high, $emphasis-high);
  }

  &:not(.p-disabled).p-focus,
  &:not(.p-disabled).p-inputwrapper-focus {
    border-color: transparent;

    background-color: $inputFilledFocusBg;
    background-size: 100% 2px, 100% 1px;
    box-shadow: none;
  }

  .p-inputtext {
    &:enabled:hover,
    &:enabled:focus {
      background: transparent;
      background-image: none;
    }
  }
}
@mixin filled-input-float-label() {
  @if (nth($inputPadding, 1) == 1rem) {
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    // stylelint-disable-next-line
  } @else if (nth($inputPadding, 1) == 0.75rem) {
    padding-top: 1.25rem;
    padding-bottom: 0.25rem;
  }
}
