.p-inputswitch {
  width: $inputSwitchWidth;
  height: $inputSwitchHeight;

  .p-inputswitch-slider {
    border-radius: $inputSwitchBorderRadius;

    background: $inputSwitchSliderOffBg;

    transition: $formElementTransition;

    &::before {
      left: $inputSwitchSliderPadding;

      width: $inputSwitchHandleWidth;
      height: $inputSwitchHandleHeight;
      margin-top: -1 * $inputSwitchHandleHeight / 2;
      border-radius: $inputSwitchHandleBorderRadius;

      background: $inputSwitchHandleOffBg;

      transition-duration: $transitionDuration;
    }
  }

  &.p-inputswitch-checked {
    .p-inputswitch-slider::before {
      transform: translateX($inputSwitchHandleWidth);
    }
  }

  &.p-focus {
    .p-inputswitch-slider {
      @include focused();
    }
  }

  &:not(.p-disabled):hover {
    .p-inputswitch-slider {
      background: $inputSwitchSliderOffHoverBg;
    }
  }

  // stylelint-disable-next-line
  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      background: $inputSwitchSliderOnBg;

      // stylelint-disable-next-line
      &::before {
        background: $inputSwitchHandleOnBg;
      }
    }

    &:not(.p-disabled):hover {
      .p-inputswitch-slider {
        background: $inputSwitchSliderOnHoverBg;
      }
    }
  }

  &.p-error,
  &.p-invalid {
    @include invalid-input();
  }
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  @include invalid-input();
}
