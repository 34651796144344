.p-selectbutton {
  .p-button {
    border: $toggleButtonBorder;

    background: $toggleButtonBg;
    color: $toggleButtonTextColor;

    transition: $formElementTransition;

    .p-button-icon-left,
    .p-button-icon-right {
      color: $toggleButtonIconColor;
    }

    &:not(.p-disabled):not(.p-highlight):hover {
      border-color: $toggleButtonHoverBorderColor;

      background: $toggleButtonHoverBg;
      color: $toggleButtonTextHoverColor;

      .p-button-icon-left,
      .p-button-icon-right {
        color: $toggleButtonIconHoverColor;
      }
    }

    &.p-highlight {
      border-color: $toggleButtonActiveBorderColor;

      background: $toggleButtonActiveBg;
      color: $toggleButtonTextActiveColor;

      .p-button-icon-left,
      .p-button-icon-right {
        color: $toggleButtonIconActiveColor;
      }

      &:hover {
        border-color: $toggleButtonActiveHoverBorderColor;

        background: $toggleButtonActiveHoverBg;
        color: $toggleButtonTextActiveHoverColor;

        .p-button-icon-left,
        .p-button-icon-right {
          color: $toggleButtonIconActiveHoverColor;
        }
      }
    }
  }

  &.p-error > .p-button,
  &.p-invalid > .p-button {
    @include invalid-input();
  }
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
  @include invalid-input();
}
