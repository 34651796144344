.p-treetable {
  .p-sortable-column {
    outline: 0 none;

    &:focus {
      background-color: rgba($overlayColor, 0.03);
    }
  }

  .p-treetable-tbody {
    > tr {
      outline: 0 none;

      &:not(.p-highlight):focus {
        background-color: rgba($overlayColor, 0.03);
      }
    }
  }
}
