.au-navigation-panel {
  position: sticky;
  top: 0;

  background-color: $navigationPanelBgColor;
}

.au-navigation-panel-content {
  overflow: auto;

  width: $navigationPanelWidth;
  height: 100vh;
  padding-top: $navigationPanelPadding;
  border-right: $navigationPanelBorder;

  &::-webkit-scrollbar {
    width: $navigationPanelScrollBarWidth;
    height: $navigationPanelScrollBarWidth;
  }

  &::-webkit-scrollbar-thumb {
    background: $navigationPanelScrollThumbBg;
  }
}
