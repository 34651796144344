.p-badge {
  min-width: $badgeMinWidth;
  height: $badgeHeight;

  background: $badgeBg;
  color: $badgeTextColor;

  font-weight: $badgeFontWeight;
  font-size: $badgeFontSize;
  line-height: $badgeHeight;

  &.p-badge-secondary {
    background-color: $secondaryButtonBg;
    color: $secondaryButtonTextColor;
  }

  &.p-badge-success {
    background-color: $successButtonBg;
    color: $successButtonTextColor;
  }

  &.p-badge-info {
    background-color: $infoButtonBg;
    color: $infoButtonTextColor;
  }

  &.p-badge-warning {
    background-color: $warningButtonBg;
    color: $warningButtonTextColor;
  }

  &.p-badge-danger {
    background-color: $dangerButtonBg;
    color: $dangerButtonTextColor;
  }

  &.p-badge-lg {
    min-width: 1.5 * $badgeMinWidth;
    height: 1.5 * $badgeHeight;

    font-size: 1.5 * $badgeFontSize;
    line-height: 1.5 * $badgeHeight;
  }

  &.p-badge-xl {
    min-width: 2 * $badgeMinWidth;
    height: 2 * $badgeHeight;

    font-size: 2 * $badgeFontSize;
    line-height: 2 * $badgeHeight;
  }
}
