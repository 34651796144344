.p-panel {
  .p-panel-header {
    padding: $panelHeaderPadding;
    border: $panelHeaderBorder;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;

    background: $panelHeaderBg;
    color: $panelHeaderTextColor;

    .p-panel-title {
      font-weight: $panelHeaderFontWeight;
    }

    .p-panel-header-icon {
      @include action-icon();
    }
  }

  &.p-panel-toggleable {
    .p-panel-header {
      padding: $panelToggleableHeaderPadding;
    }
  }

  .p-panel-content {
    padding: $panelContentPadding;
    border: $panelContentBorder;
    border-top: 0 none;
    border-bottom-right-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;

    background: $panelContentBg;
    color: $panelContentTextColor;
  }

  .p-panel-footer {
    padding: $panelFooterPadding;
    border: $panelFooterBorder;
    border-top: 0 none;

    background: $panelFooterBg;
    color: $panelFooterTextColor;
  }
}
