.p-splitter {
  border: $panelContentBorder;
  border-radius: $borderRadius;

  background: $panelContentBg;
  color: $panelContentTextColor;

  .p-splitter-gutter {
    background: $splitterGutterBg;

    transition: $actionIconTransition;

    .p-splitter-gutter-handle {
      background: $splitterGutterHandleBg;
    }
  }

  .p-splitter-gutter-resizing {
    background: $splitterGutterHandleBg;
  }
}
