.p-slider {
  border: $sliderBorder;
  border-radius: $borderRadius;

  background: $sliderBg;

  &.p-slider-horizontal {
    height: $sliderHorizontalHeight;

    .p-slider-handle {
      margin-top: (-1 * $sliderHandleHeight / 2);
      margin-left: (-1 * $sliderHandleWidth / 2);
    }
  }

  &.p-slider-vertical {
    width: $sliderVerticalWidth;

    .p-slider-handle {
      margin-bottom: (-1 * $sliderHandleHeight / 2);
      margin-left: (-1 * $sliderHandleWidth / 2);
    }
  }

  .p-slider-handle {
    width: $sliderHandleWidth;
    height: $sliderHandleHeight;
    border: $sliderHandleBorder;
    border-radius: $sliderHandleBorderRadius;

    background: $sliderHandleBg;

    transition: $formElementTransition;

    &:focus {
      @include focused();
    }
  }

  .p-slider-range {
    background: $sliderRangeBg;
  }

  &:not(.p-disabled) {
    .p-slider-handle:hover {
      border-color: $sliderHandleHoverBorderColor;

      background: $sliderHandleHoverBg;
    }
  }

  &.p-slider-animate {
    &.p-slider-horizontal {
      .p-slider-handle {
        transition: $formElementTransition, left $transitionDuration;
      }

      .p-slider-range {
        transition: width $transitionDuration;
      }
    }

    &.p-slider-vertical {
      .p-slider-handle {
        transition: $formElementTransition, bottom $transitionDuration;
      }

      .p-slider-range {
        transition: height $transitionDuration;
      }
    }
  }
}
