.p-overlaypanel {
  border: $overlayContentBorder;
  border-radius: $borderRadius;

  background: $overlayContentBg;
  color: $panelContentTextColor;
  box-shadow: $overlayContainerShadow;

  .p-overlaypanel-content {
    padding: $panelContentPadding;
  }

  .p-overlaypanel-close {
    position: absolute;
    top: -1 * $actionIconWidth / 2;
    right: -1 * $actionIconWidth / 2;

    width: $actionIconWidth;
    height: $actionIconHeight;
    border-radius: $actionIconBorderRadius;

    background: $buttonBg;
    color: $buttonTextColor;

    transition: $actionIconTransition;

    &:enabled:hover {
      background: $buttonHoverBg;
      color: $buttonTextHoverColor;
    }
  }

  &::after {
    border: solid transparent;
    border-color: rgba($overlayContentBg, 0);
    border-bottom-color: $overlayContentBg;
  }

  &::before {
    border: solid transparent;

    @if (nth($overlayContentBorder, 2) == 'none') {
      border-color: rgba($overlayContentBg, 0);
      border-bottom-color: $overlayContentBg;
      // stylelint-disable-next-line
    } @else {
      border-color: rgba(nth($overlayContentBorder, 3), 0);
      border-bottom-color: nth($overlayContentBorder, 3);
    }
  }

  &.p-overlaypanel-flipped {
    &::after {
      border-top-color: $overlayContentBg;
    }

    &::before {
      @if (nth($overlayContentBorder, 2) == 'none') {
        border-top-color: $overlayContentBg;
        // stylelint-disable-next-line
      } @else {
        border-top-color: nth($overlayContentBorder, 3);
      }
    }
  }
}
