.p-tabmenu {
  .p-tabmenu-nav {
    border: $tabviewNavBorder;
    border-width: $tabviewNavBorderWidth;

    background: $tabviewNavBg;

    .p-tabmenuitem {
      margin-right: $tabviewHeaderSpacing;

      .p-menuitem-link {
        margin: $tabviewHeaderMargin;
        padding: $tabviewHeaderPadding;
        border: $tabviewHeaderBorder;
        border-width: $tabviewHeaderBorderWidth;
        border-color: $tabviewHeaderBorderColor;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;

        background: $tabviewHeaderBg;
        color: $tabviewHeaderTextColor;

        font-weight: $tabviewHeaderFontWeight;

        transition: $listItemTransition;

        .p-menuitem-icon {
          margin-right: $inlineSpacing;
        }

        &:not(.p-disabled):focus {
          @include focused();
        }
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        .p-menuitem-link {
          border-color: $tabviewHeaderHoverBorderColor;

          background: $tabviewHeaderHoverBg;
          color: $tabviewHeaderTextHoverColor;
        }
      }

      &.p-highlight {
        .p-menuitem-link {
          border-color: $tabviewHeaderActiveBorderColor;

          background: $tabviewHeaderActiveBg;
          color: $tabviewHeaderTextActiveColor;
        }
      }
    }
  }
}
