.p-steps {
  .p-steps-item {
    .p-menuitem-link {
      border-radius: $borderRadius;

      background: transparent;
      background: $stepsItemBg;

      transition: $listItemTransition;

      .p-steps-number {
        z-index: 1;

        min-width: $stepsItemNumberWidth;
        height: $stepsItemNumberHeight;
        border: $stepsItemBorder;
        border-radius: $stepsItemNumberBorderRadius;

        background: $stepsItemBg;
        color: $stepsItemNumberColor;

        font-size: $stepsItemNumberFontSize;
        line-height: $stepsItemNumberHeight;
      }

      .p-steps-title {
        margin-top: $inlineSpacing;

        color: $stepsItemTextColor;
      }

      &:not(.p-disabled):focus {
        @include focused();
      }
    }

    &.p-highlight {
      .p-steps-number {
        background: $highlightBg;
        color: $highlightTextColor;
      }

      .p-steps-title {
        color: $textColor;

        font-weight: $stepsItemActiveFontWeight;
      }
    }

    &::before {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 0;
      display: block;

      width: 100%;
      margin-top: -1 * ($stepsItemNumberHeight / 2);
      border-top: $divider;
    }
  }
}
