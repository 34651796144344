@import '../../styles/theme/custom-variables';
@import '../typo/typo.component.scss';

.au-upload-image {
  border: 1px dashed $uploadImageBorderColor;
  border-radius: 4px;

  & .p-fileupload,
  & .p-fileupload-content {
    height: 100%;
  }

  & .p-fileupload-buttonbar,
  & .p-progressbar {
    display: none;
  }

  & .p-fileupload-content {
    padding: 0;
    border: none;
  }
}

.au-upload-image-preview {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  & .p-button-rounded {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    display: none;
  }

  &:hover {
    & .p-button-rounded {
      display: flex;
    }
  }
}

.au-upload-content {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  background-color: $uploadImageBgColor;

  &:hover {
    cursor: pointer;
  }
}

.au-upload-image-caption {
  @extend .au-typo-body-caption;

  margin: 0;
  padding: 0.5rem;

  color: $uploadImageCaptionColor;

  text-align: center;

  & span {
    color: var(--primary-color);
  }
}

.au-upload-image-list {
  display: flex;

  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;

  list-style: none;

  line-height: 0;
}

.au-upload-image-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 1rem;

  &:only-child,
  &:last-child {
    margin-right: 0;
  }

  & .p-button-rounded {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    display: none;
  }

  &:hover {
    & .p-button-rounded {
      display: flex;
    }
  }
}

.au-upload-image-image {
  max-width: 100%;
  max-height: 100%;
}
