.p-tabview {
  .p-tabview-nav {
    border: $tabviewNavBorder;
    border-width: $tabviewNavBorderWidth;

    background: $tabviewNavBg;

    li {
      margin-right: $tabviewHeaderSpacing;

      .p-tabview-nav-link {
        margin: $tabviewHeaderMargin;
        padding: $tabviewHeaderPadding;
        border: $tabviewHeaderBorder;
        border-width: $tabviewHeaderBorderWidth;
        border-color: $tabviewHeaderBorderColor;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;

        background: $tabviewHeaderBg;
        color: $tabviewHeaderTextColor;

        font-weight: $tabviewHeaderFontWeight;

        transition: $listItemTransition;

        &:not(.p-disabled):focus {
          @include focused();
        }
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        .p-tabview-nav-link {
          border-color: $tabviewHeaderHoverBorderColor;

          background: $tabviewHeaderHoverBg;
          color: $tabviewHeaderTextHoverColor;
        }
      }

      &.p-highlight {
        .p-tabview-nav-link {
          border-color: $tabviewHeaderActiveBorderColor;

          background: $tabviewHeaderActiveBg;
          color: $tabviewHeaderTextActiveColor;
        }
      }
    }
  }

  .p-tabview-left-icon {
    margin-right: $inlineSpacing;
  }

  .p-tabview-right-icon {
    margin-left: $inlineSpacing;
  }

  .p-tabview-close {
    margin-left: $inlineSpacing;
  }

  .p-tabview-panels {
    padding: $tabviewContentPadding;
    border: $tabviewContentBorder;
    border-bottom-right-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;

    background: $tabviewContentBg;
    color: $tabviewContentTextColor;
  }
}
