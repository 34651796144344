.p-progressbar {
  height: $progressBarHeight;
  border: $progressBarBorder;
  border-radius: $borderRadius;

  background: $progressBarBg;

  .p-progressbar-value {
    margin: 0;
    border: 0 none;

    background: $progressBarValueBg;
  }

  .p-progressbar-label {
    color: $textColor;

    line-height: $progressBarHeight;
  }
}
