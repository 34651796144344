.p-panelmenu {
  & .p-panelmenu-panel:first-child {
    border-radius: 0;

    & .p-panelmenu-header > a {
      border-radius: 0;
    }
  }

  & .p-panelmenu-panel {
    box-shadow: none;
  }

  & .p-panelmenu-header > a {
    background: transparent;

    font-weight: bold;
  }

  & .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding: 0.75rem 0.75rem 0.75rem 3rem;
  }

  & .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
    padding: 0 0 0 2rem;
  }

  & .p-panelmenu-header.p-highlight > a {
    background: $accordionHeaderHoverBg;
    color: $primaryColor;
  }

  & .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
    background: $accordionHeaderHoverBg;
  }

  & .p-panelmenu-content .p-menuitem {
    & .p-menuitem-link.p-menuitem-link-active .p-menuitem-text {
      color: $primaryColor;
    }
  }
}
