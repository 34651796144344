html {
  height: 100%;

  font-size: 14px;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;

  background-color: var(--surface-a);
  color: var(--text-color);

  font-family: $fontFamily;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 1.5rem 0;

  color: inherit;

  font-weight: normal;
  font-family: inherit;

  &:first-child {
    margin-top: 0;
  }
}

h1 {
  @extend .au-typo-h1;
}

h2 {
  @extend .au-typo-h2;
}

h3 {
  @extend .au-typo-h3;
}

h4 {
  @extend .au-typo-h4;
}

h5 {
  @extend .au-typo-h5;
}

h6 {
  @extend .au-typo-h6;

  margin: 0;
}

p {
  @extend .au-typo-body;

  margin: 0 0 1rem 0;
}
