.p-menu {
  & .p-menuitem:last-child {
    & .p-menuitem-icon.pi.pi-trash {
      color: var(--danger-color);

      &:hover {
        color: var(--danger-color);
      }

      & ~ .p-menuitem-text {
        color: var(--danger-color);
      }
    }
  }

  & .p-menuitem-link {
    & .p-menuitem-text {
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    & .p-menuitem-icon {
      margin-right: 19px;

      color: var(--text-color);

      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
    }

    &:not(.p-disabled):hover {
      & .p-menuitem-icon {
        color: var(--text-color);
      }
    }
  }
}
