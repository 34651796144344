.p-toolbar {
  $root: &;

  &-primary {
    background-color: $primaryColor;
  }

  &-no-radius {
    border-radius: 0;
  }
}
