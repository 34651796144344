.p-inline-message {
  margin: $inlineMessageMargin;
  padding: $inlineMessagePadding;
  border-radius: $borderRadius;

  &.p-inline-message-info {
    border: $infoMessageBorder;
    border-width: $inlineMessageBorderWidth;

    background: $infoMessageBg;
    color: $infoMessageTextColor;

    .p-inline-message-icon {
      color: $infoMessageIconColor;
    }
  }

  &.p-inline-message-success {
    border: $successMessageBorder;
    border-width: $inlineMessageBorderWidth;

    background: $successMessageBg;
    color: $successMessageTextColor;

    .p-inline-message-icon {
      color: $successMessageIconColor;
    }
  }

  &.p-inline-message-warn {
    border: $warningMessageBorder;
    border-width: $inlineMessageBorderWidth;

    background: $warningMessageBg;
    color: $warningMessageTextColor;

    .p-inline-message-icon {
      color: $warningMessageIconColor;
    }
  }

  &.p-inline-message-error {
    border: $errorMessageBorder;
    border-width: $inlineMessageBorderWidth;

    background: $errorMessageBg;
    color: $errorMessageTextColor;

    .p-inline-message-icon {
      color: $errorMessageIconColor;
    }
  }

  .p-inline-message-icon {
    margin-right: $inlineSpacing;

    font-size: $inlineMessageIconFontSize;
  }

  .p-inline-message-text {
    font-size: $inlineMessageTextFontSize;
  }

  &.p-inline-message-icon-only {
    .p-inline-message-icon {
      margin-right: 0;
    }
  }
}
