.p-menu {
  width: $menuWidth;
  padding: $verticalMenuPadding;
  border: $menuBorder;
  border-radius: $borderRadius;

  background: $menuBg;
  color: $menuTextColor;

  .p-menuitem-link {
    @include menuitem-link();
  }

  &.p-menu-overlay {
    border: $overlayMenuBorder;

    background: $overlayMenuBg;
    box-shadow: $overlayMenuShadow;
  }

  .p-submenu-header {
    margin: $submenuHeaderMargin;
    padding: $submenuHeaderPadding;
    border-top-left-radius: $submenuHeaderBorderRadius;
    border-top-right-radius: $submenuHeaderBorderRadius;

    background: $submenuHeaderBg;
    color: $submenuHeaderTextColor;

    font-weight: $submenuHeaderFontWeight;
  }

  .p-menu-separator {
    margin: $menuSeparatorMargin;
    border-top: $divider;
  }

  .p-menuitem {
    margin: $verticalMenuitemMargin;

    &:last-child {
      margin: 0;
    }
  }
}
